import React from 'react';



var bnr1 = require('./../../images/background/bg5.jpg');

/*const blogs = [
    {
        image: require('./../../media/blog/latest-blog/pic1.jpg'),
        title: 'Commercial design for project',
        description: 'Which is the same as saying through shrinking from toil and pain.',
        date: '14',
        month: 'April',
        year: '2022'
    },
    {
        image: require('./../../media/blog/latest-blog/pic2.jpg'),
        title: 'Our interior design prediction',
        description: 'Today we can tell you, thanks to your passion, hard work creativity.',
        date: '16',
        month: 'Feb',
        year: '2022'
    },
    {
        image: require('./../../media/blog/latest-blog/pic3.jpg'),
        title: 'Low cost interior designing ideas',
        description: 'Every pleasure is to be welcomed every pain avoided. in certain.',
        date: '18',
        month: 'Jan',
        year: '2022'
    }
]*/



class Blogs extends React.Component {

    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b30 square_shape1 bg-parallax bg-cover" data-stellar-background-ratio="0.5" >
                    <div className="container">
                        {/* TITLE START */}

                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}

                    </div>

                </div>
            </>
        );
    }
};

export default Blogs;