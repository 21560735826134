import React from 'react';
// import { NavLink } from 'react-router-dom';

/*var img1 = require('./../../media/background/bg-4.png');
var img2 = require('./../../media/background/bg-site.png');*/

class OurValue3 extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./static/js/masonary.js');

    };

    render() {
        return (
            <>

            </>
        );
    }
};

export default OurValue3;